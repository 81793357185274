import firebase from 'firebase/app';

import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

const settings = {timestampsInSnapshots: true};

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDB-6CSCNI3dEDVg2pH9lMCP9f1V6fwowo",
  authDomain: "micrositio-uv.firebaseapp.com",
  projectId: "micrositio-uv",
  storageBucket: "micrositio-uv.appspot.com",
  messagingSenderId: "949726294718",
  appId: "1:949726294718:web:53b308acb9620d13ada4ef",
  measurementId: "G-BTPM1WS1JG"
};

firebase.initializeApp(firebaseConfig);
firebase.firestore().settings(settings);

export default firebase;