<template>
  <div>
    <b-modal
      ref="modal-avisos"
      size="lg"
      hide-footer
      hide-header
      title="Avisos"
    >
      <div class="d-block">
        <img
          class="logocongresomodal"
          src="../assets/logos/escudo-XVII-Leg-gris.svg"
          alt="Escudo XVII Legislatura"
        />
        <h5 class="titulo-modal">Avisos</h5>
        <div class="container container-avisos">
          <div
            class="aviso shadow-sm"
            v-for="a in avisos"
            :key="a.key"
            v-html="a.aviso"
          ></div>
        </div>
      </div>
    </b-modal>
    <div class="container-fluid container-marco-welcome shadow-sm">
      <div class="container bienvenida">
        <div class="bienvenida__title">
          
          <img
            class="bienvenida__title--logo"
            src="../assets/logos/logo-uv-negro.svg"
            alt="Escudo Unidad de Vigilancia"
          />
          <h1 class="bienvenida__title--welcome">
            <span>Bienvenidos al Micrositio de la </span><br />Unidad de Vigilancia <span>de la Comisión de Hacienda, Presupuesto y Cuenta.</span>
          </h1>
          <!--<Countdown />-->
        </div>
        <div class="bienvenida__text">
          <div class="bienvenida__text--card shadow">

            <p>La Unidad de Vigilancia de la Comisión de Hacienda Presupuesto y Cuenta es un órgano técnico de la Comisión de Hacienda Presupuesto y Cuenta, de conformidad con los artículos 110, 111 y 112 de la Ley de Fiscalización y Rendición de Cuentas del Estado de Quintana Roo.</p>
            <p>Esta Unidad Vigilará que el Titular de la Auditoría Superior del Estado, los Auditores Especiales y los demás servidores públicos de la Auditoría Superior del Estado en el desempeño de sus funciones, se sujeten a la Ley General de Responsabilidades Administrativas, la Ley de Responsabilidades Administrativas del Estado y las demás disposiciones legales aplicables.</p>
            <p>Es importante mencionar que, a través de este Portal, ponemos a su disposición el número telefónico y correo institucional de la Unidad, en donde nos podrán contactar para cualquier duda, sugerencia o comentarios al respecto.</p>
            <ul>
              <li>9838322822  Ext. 1121.</li>
              <li>unidaddevigilanciachpc@congresoqroo.gob.mx</li>
            </ul>
            <p>Reiteramos una cordial bienvenida a las personas interesadas en conocer más detalladamente los trabajos que se llevan a cabo en esta Unidad de Vigilancia de la Comisión de Hacienda, Presupuesto y Cuenta.</p>
            <br />
            <h4>Mtra. Judith Alejandra Medina Manrique.</h4>
            <h5>Titular de la Unidad de Vigilancia.</h5>
          </div>
        </div>
      </div>
    </div>
    
    
    <!-- <div class="container-fluid container-mision-vision shadow-sm">
      <div class="container">
        <ul class="mision-vision">
          <li>
            <h3>Misión</h3>
            <p>
              Vigilar el apego normativo en el desempeño de la Auditoría Superior del Estado de Quintana Roo, consolidando una gestión pública, eficaz, eficiente y transparente, que rinda cuentas e incorpore la participación ciudadana, así como coadyuvar con las labores de la Comisión de Hacienda, Presupuesto y Cuenta.
            </p>
          </li>
          <li>
            <h3>Visión</h3>
            <p>
              Se un órgano técnico y de control consolidado en la vigilancia, control y revisión del destino de los recursos públicos, así como del cumplimiento de los objetivos establecidos en el Plan Estatal de Desarrollo, mediante implementación de procesos de mejora continua en el desempeño de la fiscalización.
            </p>
          </li>
        </ul>
      </div>
    </div> -->
    <div class="container container-link-seccions">
      <div class="row">
        <div class="col-6 col-md-4" v-for="i in items" :key="i.key">
          <div class="box-link-seccion text-center">
            <a v-if="i.tipo == 1" :href="i.url" target="_blank">
              <img
                class="logo-seccion"
                :src="i.logo"
                :alt="'Logo de la sección' + i.name"
              />
              <h5 class="titulo-seccion">{{ i.name }}</h5>
            </a>
            <router-link v-if="i.tipo == 2" :to="'/seccion/' + i.key">
              <img
                class="logo-seccion"
                :src="i.logo"
                :alt="'Logo de la sección' + i.name"
              />
              <h5 class="titulo-seccion">{{ i.name }}</h5>
            </router-link>
            <p>{{ i.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "../firebase";
import Countdown from "../components/Countdown.vue";

export default {
  name: "Home",
  components: { Countdown },
  data() {
    return {
      show: false,
      ref: firebase.firestore().collection("secciones"),
      refAvisos: firebase.firestore().collection("avisos"),
      items: {},
      avisos: {},
      modal: false,
    };
  },
  created() {
    this.ref.orderBy("orden").onSnapshot((querySnapshot) => {
      this.items = [];
      querySnapshot.forEach((doc) => {
        this.items.push({
          key: doc.id,
          name: doc.data().name,
          description: doc.data().description,
          tipo: doc.data().tipo,
          logo: doc.data().logo,
          url: doc.data().url,
        });
      });
    });

    this.refAvisos.where("activo", "==", true).onSnapshot((querySnapshot) => {
      this.avisos = [];
      querySnapshot.forEach((doc) => {
        this.avisos.push({
          key: doc.id,
          aviso: doc.data().aviso,
        });
      });

      if (this.avisos.length != 0) {
        this.showModal();
      }
    });
  },
  methods: {
    showModal() {
      this.$refs["modal-avisos"].show();
    },
  },
};
</script>

<style lang="scss" scope>
@import "../scss/abstracts/variables";

.container-marco-welcome {
  padding-top: 4rem;
  padding-bottom: 2.5rem;
  background-image: url(../assets/logos/vector-fondo.svg);
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: white;
  border-bottom: 1px solid $bg-02;
}

.bienvenida {
  display: flex;
  align-items: center;
  flex-direction: column;
  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }

  &__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    &--welcome {
      font-family: $font-01;
      font-size: 1.75rem;
      width: auto;
      text-align: center;
      margin: 0 auto;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-bottom: 2rem;
      @include media-breakpoint-up(md) {
        width: 400px;
        font-size: 2.25rem;
        padding-left: 0;
        padding-right: 2rem;
        //padding-top: 3rem;
      }
      @include media-breakpoint-up(lg) {
        font-size: 2.8rem;
        text-align: left;
        padding-left: 2rem;
      }
      span {
        font-size: 1.4rem;
        font-weight: 200;
        @include media-breakpoint-up(lg) {
          font-size: 1.8rem;
        }
        @include media-breakpoint-up(xl) {
          font-size: 2rem;
        }
      }
    }
    &--logo {
      width: 10rem;
      margin-bottom: 2rem;
      @include media-breakpoint-up(md) {
        width: 15rem;
      }
    }
  }
  
  &__text {
    border: 1px solid $color-03;
    width: 100%;

    .fondo {
      background: $color-02;
      height: 80px;
    }
    &--card{
      
      padding: 1rem;
      background: $bg-03;
      font-size: 1rem;

      @include media-breakpoint-up(md) {
        padding: 2rem;
      }
      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        border: 4px solid white;
        margin-top: -5rem;
        margin-bottom: 1rem;
      }
      h4,
      h5,
      p {
        font-family: $font-02;
        font-weight: 400;
      }
      h4 {
        text-align: left;
        text-transform: uppercase;
        font-size: 1rem;
        @include media-breakpoint-up(md) {
          font-size: 1.1rem;
        }
      }
      h5 {
        text-align: left;
        color: $color-03;
        font-size: 0.8rem;
        font-weight: 700;
        @include media-breakpoint-up(md) {
          font-size: 0.9rem;
        }
      }
      p {
        font-weight: 300;
        color: $color-02;
        font-size: 0.85rem;
        text-align: justify;
      }
      ul  {
        font-size: 0.8rem;
        text-align: left;
        padding-left: 0.5rem;
        font-size: 0.65rem;
        @include media-breakpoint-up(md) {
          font-size: 0.8rem;
        }
      }
      a {
        color: $color-03;
      }
      .gracias {
        text-align: right;
        font-style: italic;
        color: $color-03;
        font-weight: 700;
      }
    }
  }
}


.container-mision-vision {
  background: $bg-03;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-top: 1px solid $bg-02;
  border-bottom: 1px solid $bg-02;
  @include media-breakpoint-up(md) {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  @include media-breakpoint-up(lg) {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .mision-vision {
    list-style: none;
    padding-left: 0;

    li {
      padding: 1.5rem 0.5rem;
      border-top: 1px solid $bg-02;
      &:first-child {
        border-top: none;
      }
      @include media-breakpoint-up(md) {
        padding-left: 3rem;
        padding-right: 3rem;
      }
      @include media-breakpoint-up(lg) {
        width: 50%;
        display: inline-block;
        border-top: none;
        border-left: 1px solid $bg-02;
        &:first-child {
          border-left: none;
        }
      }

      h3 {
        font-family: $font-01;
        font-weight: 400;
      }
      p {
        font-family: $font-02;
        font-weight: 300;
        color: $color-02;
        text-align: justify;
        font-size: 1rem;
      }
    }
  }
}

.container-link-seccions {
  margin-top: 3rem;
  margin-bottom: 3rem;

  .box-link-seccion {
    margin: 0 auto;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    @include media-breakpoint-up(md) {
      max-width: 250px;
    }

    a {
      cursor: pointer;
      color: $color-01;
      text-decoration: none;

      &:hover {
        .titulo-seccion {
          color: $color-03;
        }
      }
      .logo-seccion {
        height: 70px;
        @include media-breakpoint-up(md) {
          height: 100px;
        }
      }
      .titulo-seccion {
        font-family: $font-01;
        font-size: 1rem;
        font-weight: 300;
        margin-top: 1.5rem;
        @include media-breakpoint-up(md) {
          font-size: 1.25rem;
        }
      }
    }
    p {
      font-size: 0.6rem;
      @include media-breakpoint-up(md) {
        font-size: 0.75rem;
      }
    }
  }
}
</style>
