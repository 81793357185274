<template>
  <div class="container-fluid footer d-flex align-items-center">
    <div class="container">
      <p>
        C. Miguel Hidalgo #133 esquina con 22 de enero, edificio Bahía Diamante 2do. piso. 
        <br />Col. Barrio Bravo. C.P. 77098. Chetumal, Quintana Roo.
        <br />Teléfono de Oficina: <a href="tel:983-832-2822">(983) 832 2822</a> ext: 121
      </p>
      <p>
        Correo electrónico para recepción de documentos:
        <a href="mailto:declaracionesuv@congresoqroo.gob.mx">
          declaracionesuv@congresoqroo.gob.mx
        </a>
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scope>
@import "../scss/abstracts/variables";

.footer {
  text-align: center;
  background: $color-02;
  color: white;
  height: 200px;
  p {
    margin-bottom: 0;
    font-size: 0.75rem;
    @include media-breakpoint-up(md) {
      font-size: 0.9rem;
    }
    a {
      color: $color-03;
      &:hover {
      }
    }
  }
}
</style>
