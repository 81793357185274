<template>
  <div>
    <p v-if="items.length == 0" class="text-center">
      Aún no se han cargado Textos
    </p>
    <b-list-group v-else>
      <b-list-group-item
        v-for="i in items"
        :key="i.key"
        class="d-flex align-items-center list-documents"
      >
        <template v-if="i.edit === false">
          <span class="indicaStatus">
            <i v-if="i.activo == true" class="far fa-check-circle activo"></i>
            <i v-else class="far fa-times-circle desactivo"></i>
          </span>
          <span class="mr-auto"><strong>{{ i.orden }} </strong>{{ i.titulo }}</span>

          <b-button
            class="button-edit"
            v-b-tooltip.hover
            title="Editar"
            @click="setEditDocument(i.key)"
          >
            <i class="fas fa-pencil-alt"></i>
          </b-button>
          <b-button
            class="button-delete"
            @click.stop="deleteDocument(i.key)"
            v-b-tooltip.hover
            title="Eliminar"
          >
            <i class="far fa-trash-alt"></i>
          </b-button>
        </template>
        <div v-else class="container-edit">
          <div class="container">
            <div class="form-row">
              <div class="col-12">
                <b-form-input
                  v-model.trim="i.titulo"
                  type="text"
                  placeholder="Ingresa el Título"
                ></b-form-input>
              </div>
              <div class="col-12">
                <vue-editor id="texto" v-model="i.texto" />
              </div>
              <div class="col-2">
                <b-form-input
                  v-model.number="i.orden"
                  type="number"
                  placeholder="Orden"
                ></b-form-input>
                </div>
              <div class="col-4">
                <p>¿Activar Texto?</p>
                <switches v-model="i.activo" color="blue"></switches>
              </div>
            </div>
            <b-button class="accept mr-3" @click="setUpdateDocument(i)">
              Actualizar
            </b-button>
            <b-button
              v-b-tooltip.hover
              title="Cancelar Edición"
              @click="cancelEditDocument(i.key)"
            >
              Cancelar
            </b-button>
          </div>
        </div>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import firebase from "../firebase";
import Switches from "vue-switches";
import { VueEditor } from "vue2-editor";

export default {
  name: "ListText",
  props: ["docto", "typePub"],
  components: {
    Switches,
    VueEditor,
  },
  data() {
    return {
      ref: firebase
        .firestore()
        .collection("secciones")
        .doc(this.docto)
        .collection("documentos"),
      items: {},
      showEdit: true,
      enabled: false,
    };
  },
  created() {
    this.ref
      .onSnapshot((querySnapshot) => {
        this.items = [];
        querySnapshot.forEach((doc) => {
          this.items.push({
            key: doc.id,
            ...doc.data(),
          });
        });
        //console.log(this.items)
      });
  },
  methods: {
    setEditDocument(id) {
      const updateRef = this.ref.doc(id);
      updateRef
        .update({
          edit: true,
        })
        .then(() => {
          //console.log("Se actualizó")
        })
        .catch((error) => {
          alert("Error al Actuaizar el documento: ", error);
        });
    },
    setUpdateDocument(id) {
      const updateRef = this.ref.doc(id.key);
      updateRef
        .update({
          titulo: id.titulo,
          texto: id.texto,
          orden: id.orden,
          activo: id.activo,
          edit: false,
        })
        .then(() => {
          //console.log("Se actualizó")
          this.enabled === false;
        })
        .catch((error) => {
          alert("Error al Actuaizar el documento: ", error);
        });
    },
    cancelEditDocument(id) {
      const updateRef = this.ref.doc(id);
      updateRef
        .update({
          edit: false,
        })
        .then(() => {
          //console.log("Se actualizó")
        })
        .catch((error) => {
          alert("Error al cancelar la Edición del Documento: ", error);
        });
    },
    deleteDocument(id) {
      this.$swal({
        title: "¿Realmente quiere eliminar este Documento?",
        text: "¡Una vez eliminado ya no podrá recuperar la información!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ff689b",
        cancelButtonColor: "#5ee276",
        confirmButtonText: "Si, eliminar",
        cancelButtonText: "No, cancelar",
        //closeOnCancel: true
      }).then((result) => {
        //send request to server
        if (result.isConfirmed) {
          firebase
            .firestore()
            .collection("secciones")
            .doc(this.docto)
            .collection("documentos")
            .doc(id)
            .delete()
            .then(() => {
              //console.log("Eliminado")
            })
            .catch((error) => {
              alert("Error al eliminar el documento: ", error);
            });
          this.$swal(
            "Registro Eliminado",
            "El registro ha sido eliminado con éxito.",
            "success"
          );
        }
      });
    },
  },
};
</script>

<style lang="scss" scope>
@import "../scss/abstracts/variables";
.indicaStatus {
  margin-right: 0.5rem;
  i {
    font-size: 1.5rem;
  }
}
.text-aviso {
  padding: 0 1rem;
}
.activo {
  color: $accept;
}
.desactivo {
  color: $delete;
}
</style>