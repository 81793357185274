<template>
  <div class="container">
    <div class="container">
      <b-form @submit.prevent="onSubmit">
        <div class="form-row">
          <div class="col-12">
            <b-form-input
              v-model.trim="doc.titulo"
              type="text"
              placeholder="Título del Texto"
            ></b-form-input>
          </div>
          <div class="col-12">
            <vue-editor id="texto" v-model="doc.texto" />
          </div>
          <div class="col-2">
            <b-form-input
              v-model.number="doc.orden"
              type="number"
              placeholder="Orden"
            ></b-form-input>
          </div>
          <div class="col-4">
            <p>¿Activar Aviso?</p>
            <switches v-model="doc.activo" color="blue"></switches>
          </div>
        </div>
        <b-button
          class="accept"
          v-if="doc.titulo && doc.texto && doc.orden"
          type="submit"
        >
          Guardar Texto
        </b-button>
      </b-form>
    </div>
  </div>
</template>

<script>
import firebase from "../firebase";
import Switches from "vue-switches";
import { VueEditor } from "vue2-editor";

export default {
  name: "NewText",
  props: ["docto"],
  components: {
    VueEditor,
    Switches,
  },
  data() {
    return {
      ref: firebase
        .firestore()
        .collection("secciones")
        .doc(this.docto)
        .collection("documentos"),
      doc: {},
      enabled: false,
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      this.doc.edit = false;

      this.ref
        .add(this.doc)
        .then((docRef) => {
          (this.doc.titulo = ""), 
          (this.doc.texto = ""),
          (this.doc.orden = ""),
          (this.doc.activo = ""), 
          (this.edit = false);
        })
        .catch((error) => {
          alert("Error al agregar el documento: ", error);
        });
    },
  },
};
</script>

<style lang="scss" scope>
@import "../scss/abstracts/variables";
.container-switch {
  text-align: center;
  p {
    margin-top: 0.5rem;
  }
}
</style>
